import styled from 'styled-components'
import Modal from 'react-modal'
import { breakPoints } from '@constants/styles'

// TODO: Remove older calendar legacy code, as we're using muix date-picker now.
export const SchedulerModal = styled(Modal)`
  margin: auto;
  padding: 2rem;
  background: #fff;
  border: solid #ccc 0.4rem;
  width: 60rem;
  @media screen and (max-width: ${breakPoints.medium}) {
    padding: 1rem;
    width: 90%;
  }
`

export const CloseButton = styled.button`
  position: relative;
  background: #0053a0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  float: right;
  margin-top: -15px;
  > .close {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 2rem;
    height: 2rem;
    margin: auto;
    width: 100% !important;
    background-size: 100% !important;
    @media only screen and (min-device-width: 374px) and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
      margin-top: 0.3rem !important;
    }
  }
`

export const Heading = styled.p`
  margin-top: 10px;
  font-size: 24px;
  font-weight: normal;
  color: #0053a0;
  text-align: left;
`

export const Subheading = styled.p`
  font-size: 16px;
  margin: 1rem 0;
`

export const Button = styled.button`
  background: #0053a0;
  font-size: 0.8rem;
  color: white;
  padding: 1.2rem 1.5rem;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
`

export const ChangeLocationButtonWrapper = styled.div`
  height: 55px;
  align-self: end;
  @media screen and (max-width: ${breakPoints.small}) {
    align-self: start;
    margin-top: 10px;
  }
`
export const LocationPinWrapper = styled.div`
  @media screen and (max-width: ${breakPoints.small}) {
    width: 13px;
  }
`

export const SubmitButton = styled.div`
  margin-top: 30px;
`

export const ErrorMessage = styled.p`
  color: #eb141f;
  font-size: 1rem;
  visibility: ${({ shouldShow }) => (shouldShow ? 'visible' : 'hidden')};
  margin: 0.5rem 0;
`

export const SuccessMessage = styled.p`
  text-align: center;
  color: #008550;
  font-size: 1rem;
  margin: 0.5rem 0;
`

export const LocationSection = styled.div`
  background-color: #e4f2fe;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  margin: 28px 0;
  @media screen and (max-width: ${breakPoints.small}) {
    flex-direction: column;
  }
`
export const LocationTitle = styled.div`
  height: 48px;
  width: 411px;
  color: #0053a0;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.23px;
  line-height: 24px;
  @media screen and (max-width: ${breakPoints.small}) {
    margin-top: -20px;
    margin-left: 17px;
  }
`
export const LocationAddress = styled.div`
  width: 312px;
  color: #0053a0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  @media screen and (max-width: ${breakPoints.small}) {
    width: 100%;
  }
`

export const LocationPhone = styled.div`
  height: 57px;
  width: 312px;
  color: #0053a0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`

export const LocationOpeningTime = styled.div`
  height: 24px;
  width: 312px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
`
export const RequiredFieldsMessage = styled.div`
  font-size: 11px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: right;
  > span {
    color: #eb141f;
  }
`
export const SeniorMessage = styled.div`
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  max-width: 300px;
  margin-left: 10px;
  @media screen and (max-width: ${breakPoints.small}) {
    display: block;
    max-width: 100%;
    margin-top: 10px;
  }
`

export const RedSpan = styled.span`
  color: #eb141f;
  font-style: italic;
`
export const DateTimeFlexContainer = styled.div`
  display: flex;
  @media screen and (max-width: ${breakPoints.small}) {
    display: block;
  }
`

export const DateTimeArrow = styled.span`
  color: #a6a6a6;
  font-size: 30px;
  margin: 10px;
  @media screen and (max-width: ${breakPoints.small}) {
    display: none;
  }
`

export const TimeFieldWrapper = styled.div`
  display: inline;
  width: 193px;
  @media screen and (max-width: ${breakPoints.small}) {
    display: block;
    width: 100%;
    margin-top: 10px;
  }
`

export const DateInput = styled.input`
  height: 2.2rem;
  width: 100%;
  cursor: pointer;
`

export const Label = styled.label`
  color: #565f6f;
  font-weight: bold;
  margin: 0 0.5rem;
  min-width: 3rem;
  font-size: 0.8rem;
`
