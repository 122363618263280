import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { store } from '@redux/store'
import { weekdays, getStandardTime } from '@helpers/string-helper'
import RTGLink from '../shared/link'
import '../../assets/css/components/store-locator/store-info.sass'

const StoreInfo = ({ selectedMarker }) => {
  let hours
  if (selectedMarker.hours && !selectedMarker.hours.regularHours) {
    hours = selectedMarker.hours
  } else if (selectedMarker.hours && selectedMarker.hours.regularHours) {
    hours = selectedMarker.hours.regularHours
  }
  const currentDay = weekdays[new Date().getDay()]
  return (
    <Provider store={store}>
      <div className="store-info">
        <h2 className="store-name">{selectedMarker.name}</h2>
        <div className="storeAddress">
          <RTGLink
            data={{
              url: `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${selectedMarker.lat},${selectedMarker.lng}`,
              title: `Directions to ${selectedMarker.name}`,
              category: 'store locator',
              action: 'Directions store map click',
              label: 'Directions to store',
            }}
            target="_blank"
            className="store-address-link"
          >
            {selectedMarker.title}
            <br />
            {selectedMarker.city}, {selectedMarker.state} {selectedMarker.zip}
          </RTGLink>
          <br />
          {(selectedMarker.phone || selectedMarker.phoneNumber) && (
            <RTGLink
              data={{
                url: `tel:${(selectedMarker.phone ? selectedMarker.phone : selectedMarker.phoneNumber).replace(
                  '/',
                  '-',
                )}`,
                title: `Call ${selectedMarker.name}`,
                category: 'store locator',
                action: 'Call store map click',
                label: 'Call store',
              }}
            >
              {(selectedMarker.phone ? selectedMarker.phone : selectedMarker.phoneNumber).replace('/', '-')}
            </RTGLink>
          )}
          {hours && (
            <div className="today-hours">
              {hours &&
                hours.map(hour => {
                  const day = hour.day ? hour.day : weekdays[hour.dayIndex]
                  return (
                    day === currentDay && (
                      <div className="hour" key={hour.day}>
                        <span className="day">Today</span>:&nbsp;
                        {hour.openTime && hour.closeTime
                          ? `${getStandardTime(hour.openTime)} - ${getStandardTime(hour.closeTime)}`
                          : 'Closed'}
                      </div>
                    )
                  )
                })}
            </div>
          )}
        </div>
      </div>
    </Provider>
  )
}

StoreInfo.propTypes = {
  selectedMarker: PropTypes.shape({
    city: PropTypes.string,
    hours: PropTypes.oneOfType([
      PropTypes.shape({
        regularHours: PropTypes.array,
      }),
      PropTypes.array,
    ]),
    lat: PropTypes.any,
    lng: PropTypes.any,
    name: PropTypes.string,
    phone: PropTypes.string,
    phoneNumber: PropTypes.string,
    state: PropTypes.string,
    title: PropTypes.string,
    zip: PropTypes.string,
  }),
}

export default StoreInfo
