import React, { useState, useEffect, useRef } from 'react'
import { bool, func, object } from 'prop-types'
import { TextField, useMediaQuery } from '@mui/material'
import loaderLight from '@assets/images/loader-light.svg'
import { getStoreInfo, scheduleStoreAppointment, fetchStoreAptTimeslots } from '@services/stores'
import { convertToTwelveHourTime } from '@shared/Calendar/timestamp-utils'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'
import SelectMui from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@mui/material/Tooltip'
import { createDateWithTimeZone, getDateCalendarFormat } from '@helpers/date'
import { identifyUser } from '@components/integrations/Segment'
import { getCurrentLocation } from '@helpers/geo-location'
import { pageTheme } from '@templates/constants'

import locationPinImg from './location-pin.png'
import { getValidationErrorMsg } from './helpers'
import {
  SchedulerModal,
  CloseButton,
  Heading,
  Subheading,
  Button,
  ErrorMessage,
  SubmitButton,
  SuccessMessage,
  LocationSection,
  ChangeLocationButtonWrapper,
  LocationTitle,
  LocationAddress,
  LocationPhone,
  LocationOpeningTime,
  RequiredFieldsMessage,
  SeniorMessage,
  RedSpan,
  DateTimeFlexContainer,
  LocationPinWrapper,
  DateTimeArrow,
  TimeFieldWrapper,
} from './styles'

const useStyles = makeStyles({
  root: { width: '193px' },
})

const today = () => new Date().toLocaleString('en-us', { weekday: 'long' })

const getOpeningTime = store => {
  const hours = store.hours?.regularHours || store.hours
  let time = ''
  if (hours) {
    time = hours.find(h => h.day === today())?.closeTime
    if (!time) {
      time = hours.find(h => h.dayIndex === new Date().getDay().toString())?.closeTime
    }
  }
  if (time) {
    return convert24To12(time)
  }
  time = store.StoreHours ? store.StoreHours[`${today().toLowerCase()}Closed`] : ''
  return time ? convert24To12(time) : ''
}

const convert24To12 = H => {
  const h = H.split(':')[0] % 12 || 12
  const ampm = H < 12 ? ' AM' : ' PM'
  const ts = `${h}:${H.split(':')[1]} ${ampm}`
  return ts
}

const InStoreSchedule = ({ shouldShowModal, closeModal, store, isReschedule }) => {
  const [input, setInput] = useState({ 'email-signup': true, date: null })
  const [timeZone, setTimeZone] = useState()
  const [timeslots, setTimeslots] = useState([])
  const [isStoreClosed, setIsStoreClosed] = useState(false)
  const [validationErrorMsg, setValidationErrorMsg] = useState(null)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const classes = useStyles()
  const location = getCurrentLocation()
  const isMobile = useMediaQuery(pageTheme.breakpoints.down('sm'))

  const onChangeStoreClick = () => {
    if (typeof window !== 'undefined' && window.location && window.location.pathname === '/stores') {
      closeModal()
    } else {
      // TODO: navigation need to add code to track ga data
      navigate('/stores')
    }
  }
  const onCalenderChange = newValue => {
    const tZone = timeZone || 'America/New_York'
    const date = createDateWithTimeZone(tZone, newValue.getFullYear(), newValue.getMonth(), newValue.getDate(), 0, 0, 0)
    setInput(prevState => ({ ...prevState, date: date.getTime() }))
  }
  /* Fetch new apt timeslots when input.date is updated */
  useEffect(() => {
    if (input.date && store.StoreNumber) {
      // Retrieves date in the format yyyy-mm-dd
      const isoTimeString = new Date(input.date).toISOString().slice(0, 10)
      const updateTimeslots = async () => {
        try {
          const response = await fetchStoreAptTimeslots(store.StoreNumber, isoTimeString)
          if (!response.timeslots)
            throw new Error(
              'Something went wrong while retrieving store hours. Please try again or contact customer support.',
            )

          const { timeslots: newTimeslots } = response
          setTimeslots(newTimeslots)
          const firstRegularTimeslot = newTimeslots.find(timeslot => !timeslot.isSenior)
          setInput(previousInputData => ({ ...previousInputData, time: firstRegularTimeslot.time }))
        } catch (error) {
          setValidationErrorMsg(error.message)
        }
      }

      updateTimeslots()
    }
  }, [input.date, store.StoreNumber])

  const handleInputChange = e => {
    if (validationErrorMsg !== null) setValidationErrorMsg(null)
    const inputType = e.currentTarget.type
    setInput({
      ...input,
      name: `${input.firstName} ${input.lastName}`,
      [e.currentTarget.name]: e.currentTarget[inputType === 'checkbox' ? 'checked' : 'value'],
    })
  }

  const handleFormSubmit = async () => {
    setSubmitClicked(true)
    const errorMsg = getValidationErrorMsg(input)
    if (errorMsg) {
      setValidationErrorMsg(errorMsg)
      setSubmitClicked(false)
      return
    }
    setLoading(true)
    const { email, phone, name, time, date } = input
    const isoTimeString = new Date(date).toISOString()
    // If the leading 0 was removed after a parseInt call, re-add it
    const formattedTime = time.length !== 5 ? time.padStart(5, '0') : time
    const formattedIsoTimeString = `${isoTimeString.slice(0, 11)}${formattedTime}:00`
    const requestBody = {
      storeId: String(store.StoreNumber),
      name,
      email,
      phone: phone && phone.replace(/[ ()-]+/g, ''),
      appointmentTime: formattedIsoTimeString,
      emailSubscribed: input['email-signup'],
      phoneSubscribed: input['text-messages'],
    }
    try {
      // Segment Identify call
      identifyUser({ email, zipcode: location.zip, subsourcecode: 'rtgshowroom', signup: requestBody?.emailSubscribed })
      await scheduleStoreAppointment(requestBody)
      setSuccess(true)
      setSubmitClicked(true)
      setValidationErrorMsg(null)
      const selectedTimeslotData = timeslots.find(timeslot => timeslot.time === input.time)
      window.dataLayer.push({
        event: 'schedule_visit_submit',
        storeId: store.StoreNumber,
        appointmentTime: formattedIsoTimeString,
        appointmentType: selectedTimeslotData.isSenior ? 'senior' : 'standard',
      })
    } catch (err) {
      setSubmitClicked(false)
      if (err.message === 'Request failed with status code 429') {
        setValidationErrorMsg('Sorry, too many attempts. Please try again later or contact customer support.')
      } else {
        setValidationErrorMsg('Sorry, something went wrong. Please try again or contact customer support.')
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const getStoreData = async () => {
      const data = await getStoreInfo(store.StoreNumber)
      setTimeZone(data.timeZone)
      if (data.temporarilyClosed) {
        setIsStoreClosed(true)
      }
    }
    getStoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /* Generate Select options */
  const selectOptions = timeslots.map(timeslot => ({
    value: timeslot.time,
    label: convertToTwelveHourTime(timeslot.time),
    isSenior: timeslot.isSenior,
  }))

  /* Style regular and senior hour options */
  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: state.isSelected ? '#e8f8ff' : 'white',
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: 'black',
    }),
  }

  const selectedTimeslotData = timeslots.find(timeslot => timeslot.time === input.time)
  const timeLabelRef = useRef(null)

  useEffect(() => {
    if (timeLabelRef.current) {
      timeLabelRef.current.classList.add('Mui-focused', 'MuiInputLabel-shrink', 'default-value-label')
    }
  }, [timeslots])

  const openingTime = getOpeningTime(store)
  const testIdPostfix = isMobile ? '-mobile' : '-desktop'
  return (
    <div className="showroom-visit-modal">
      {store && shouldShowModal && (
        <SchedulerModal
          label="In Store Appointment Scheduling"
          isOpen={store && shouldShowModal}
          onRequestClose={closeModal}
          overlayClassName="modal-overlay"
        >
          <CloseButton
            data-testid={`modal-close-button${testIdPostfix}`}
            type="button"
            tabIndex="0"
            value="Close"
            aria-label="Close"
            onClick={closeModal}
          >
            <img
              className="icon close"
              alt="close icon"
              src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
            />
          </CloseButton>
          <Heading>Schedule a Showroom Visit</Heading>
          {isReschedule && !success && (
            <SuccessMessage>
              Your appointment has been cancelled. To make a new appointment, please fill in the below form.
            </SuccessMessage>
          )}
          <Subheading>
            Want to schedule a store visit to meet with one of our furniture experts? They will help you navigate the
            showroom, find just the room or piece you have in mind, and guide you through purchasing and delivery
            options.
          </Subheading>
          <LocationSection>
            <LocationPinWrapper>
              <img src={locationPinImg} alt="Location Pin" width="63" />
            </LocationPinWrapper>
            <div>
              <LocationTitle>{store?.name?.toUpperCase() || 'SHOWROOM'}</LocationTitle>
              <LocationAddress>{String(store?.title || store.Address1).toUpperCase()}</LocationAddress>
              <LocationAddress>
                {`${store.city || store.City}, ${store.state || store.State} ${store.zip || store.Zip}`.toUpperCase()}
              </LocationAddress>
              <LocationPhone>{store?.phone || store?.PhoneNumber || store?.phoneNumber}</LocationPhone>
              {openingTime ? <LocationOpeningTime>Open until {openingTime}</LocationOpeningTime> : null}
            </div>
            <ChangeLocationButtonWrapper>
              <Button data-testid={`change-location-button${testIdPostfix}`} type="button" onClick={onChangeStoreClick}>
                CHANGE LOCATION
              </Button>
            </ChangeLocationButtonWrapper>
          </LocationSection>
          <RequiredFieldsMessage>
            <span>*</span>Required Fields
          </RequiredFieldsMessage>
          {!isStoreClosed && (
            <>
              <div>
                <ErrorMessage shouldShow={!!validationErrorMsg}>{validationErrorMsg}</ErrorMessage>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <DateTimeFlexContainer>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            PopperProps={{
                              id: 'showroom-visit-schedule-datetime-picker-popper',
                            }}
                            DialogProps={{
                              id: 'showroom-visit-schedule-datetime-picker-popper',
                            }}
                            minDate={(() => {
                              const date = new Date()
                              date.setDate(date.getDate() + 1)
                              return date
                            })()}
                            placeholder="Select Date"
                            label="Date *"
                            value={input.date ? new Date(input.date) : null}
                            onChange={onCalenderChange}
                            renderInput={params => (
                              <TextField
                                classes={classes}
                                variant="outlined"
                                {...params}
                                inputProps={{ 'data-testid': `date-input${testIdPostfix}`, ...params.inputProps }}
                              />
                            )}
                            OpenPickerButtonProps={{ 'data-testid': `open-picker-button${testIdPostfix}` }}
                            renderDay={(_, __, pickersDayProps) => (
                              <PickersDay
                                {...pickersDayProps}
                                data-testid={`calendar-day-button-${getDateCalendarFormat(
                                  pickersDayProps.key,
                                )}${testIdPostfix}`}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <DateTimeArrow>&rarr;</DateTimeArrow>
                        <Tooltip title={timeslots.length === 0 ? 'Select date first' : ''} placement="top">
                          <TimeFieldWrapper>
                            <FormControl variant="outlined" classes={classes}>
                              <InputLabel ref={timeLabelRef} id="demo-simple-select-outlined-label">
                                Time *
                              </InputLabel>
                              <SelectMui
                                margin="dense"
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                disabled={timeslots.length === 0}
                                onChange={e => {
                                  setInput({
                                    ...input,
                                    time: e.target.value,
                                  })
                                }}
                                value={input.time ? input.time : null}
                                label="Time *"
                                inputProps={{ 'data-testid': `time-input${testIdPostfix}` }}
                              >
                                {selectOptions.map(item => (
                                  <MenuItem
                                    value={item.value}
                                    key={item.value}
                                    style={{ color: item.isSenior ? '#eb141f' : 'initial' }}
                                    data-testid={`time-option-${item.label}${testIdPostfix}`}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </SelectMui>
                            </FormControl>
                          </TimeFieldWrapper>
                        </Tooltip>
                      </div>
                      {selectOptions.some(item => item.isSenior) && (
                        <SeniorMessage>
                          Hours in <RedSpan>red</RedSpan> denote time dedicated for seniors and vulnerable shoppers.
                        </SeniorMessage>
                      )}
                    </DateTimeFlexContainer>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '10px 0' }} />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    <TextField
                      margin="dense"
                      required
                      fullWidth
                      id="firstName"
                      autoComplete="given-name"
                      name="firstName"
                      onChange={handleInputChange}
                      label="First Name"
                      variant="outlined"
                      inputProps={{ 'data-testid': `firstName-input${testIdPostfix}` }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      margin="dense"
                      required
                      fullWidth
                      id="lastName"
                      autoComplete="family-name"
                      name="lastName"
                      onChange={handleInputChange}
                      label="Last Name"
                      variant="outlined"
                      inputProps={{ 'data-testid': `lastName-input${testIdPostfix}` }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      id="email"
                      autoComplete="email"
                      name="email"
                      onChange={handleInputChange}
                      required
                      fullWidth
                      label="Email"
                      variant="outlined"
                      placeholder="example@mail.com"
                      inputProps={{ 'data-testid': `email-input${testIdPostfix}` }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      margin="dense"
                      fullWidth
                      required
                      type="tel"
                      id="phone"
                      autoComplete="tel"
                      name="phone"
                      onChange={handleInputChange}
                      label="Phone"
                      placeholder="555-555-5555"
                      variant="outlined"
                      inputProps={{ 'data-testid': `phone-input${testIdPostfix}` }}
                    />
                  </Grid>
                  <FormControlLabel
                    style={{ marginLeft: 2 }}
                    control={
                      <Checkbox
                        defaultChecked={input['text-messages']}
                        checked={input['text-messages']}
                        color="primary"
                        onChange={handleInputChange}
                        id="text-messages"
                        name="text-messages"
                        inputProps={{ 'data-testid': `appointment-confirmation-checkbox${testIdPostfix}` }}
                      />
                    }
                    label="I agree to receive my appointment confirmation by text or call at the number provided."
                  />
                  <FormControlLabel
                    style={{ marginLeft: 2 }}
                    control={
                      <Checkbox
                        id="email-signup"
                        name="email-signup"
                        defaultChecked={input['email-signup']}
                        color="primary"
                        checked={input['email-signup']}
                        onChange={handleInputChange}
                        inputProps={{ 'data-testid': `email-promotions-checkbox${testIdPostfix}` }}
                      />
                    }
                    label="Sign up for Rooms To Go email promotions and news letters"
                  />
                </Grid>
              </div>
              {!submitClicked && (
                <SubmitButton>
                  <Button type="button" onClick={handleFormSubmit} data-testid={`submit-button${testIdPostfix}`}>
                    {loading ? <img alt="loading" src={loaderLight} /> : 'SCHEDULE A VISIT'}
                  </Button>
                </SubmitButton>
              )}
              {success && (
                <SuccessMessage>
                  Thank you for scheduling an appointment. A confirmation has been sent to {input.email}. We look
                  forward to seeing you soon.
                </SuccessMessage>
              )}
            </>
          )}
          {isStoreClosed && <div>Sorry, the store is temporarily closed at the moment.</div>}
        </SchedulerModal>
      )}
    </div>
  )
}

InStoreSchedule.propTypes = {
  shouldShowModal: bool,
  closeModal: func,
  store: object,
  isReschedule: bool,
}

export default InStoreSchedule
