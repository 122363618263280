// eslint-disable-next-line import/prefer-default-export
export const getValidationErrorMsg = input => {
  const { email, name, time, date, phone, firstName, lastName } = input
  let errorMsg = null

  if (!/^[A-Z \- a-z]+$/.test(name)) {
    errorMsg = 'Please enter a valid name.'
  }
  if (!/\b[\w.!#$%&'*+/=?^`{|}~-]+@[\w-]+(?:\.[\w-]+)*\b/.test(email)) {
    errorMsg = 'Please enter a valid email.'
  }
  if (phone && !/^\(?([0-9]{3})\)?[- ]?([0-9]{3})[- ]?([0-9]{4})$/.test(phone)) {
    errorMsg = 'Please enter a valid phone number.'
  }
  if (!date || !time || !name || !email || !firstName || !lastName) {
    errorMsg = 'Please fill out all of the required fields marked with *'
  }
  return errorMsg
}
