import axios from 'axios'

export const getStoreInfo = storeId =>
  axios.get(`${process.env.GATSBY_GET_STORE_INFO_URL}/v1/stores/${storeId}`).then(response => response.data)

export const getAllStore = () =>
  axios.get(`${process.env.GATSBY_GET_STORE_INFO_URL}/v1/stores`).then(response => response.data)

export const scheduleStoreAppointment = body =>
  axios
    .post(`${process.env.GATSBY_SCHEDULE_STORE_APPOINTMENT_URL}/v1/appointments`, body)
    .then(response => response.data)

export const fetchStoreAptTimeslots = (storeId, date) =>
  axios
    .get(`${process.env.GATSBY_SCHEDULE_STORE_APPOINTMENT_URL}/v1/appointment-times?storeId=${storeId}&date=${date}`)
    .then(response => response.data)

export const cancelStoreAppointment = appointmentId =>
  axios.patch(`${process.env.GATSBY_SCHEDULE_STORE_APPOINTMENT_URL}/v1/appointments/${appointmentId}`)
